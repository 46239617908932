"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Share-Merits
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedContainerToJSON = exports.SharedContainerFromJSONTyped = exports.SharedContainerFromJSON = exports.instanceOfSharedContainer = void 0;
var runtime_1 = require("../runtime");
var ContainerField_1 = require("./ContainerField");
var EntityInfo_1 = require("./EntityInfo");
/**
 * Check if a given object implements the SharedContainer interface.
 */
function instanceOfSharedContainer(value) {
    var isInstance = true;
    isInstance = isInstance && "order" in value;
    isInstance = isInstance && "fields" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
exports.instanceOfSharedContainer = instanceOfSharedContainer;
function SharedContainerFromJSON(json) {
    return SharedContainerFromJSONTyped(json, false);
}
exports.SharedContainerFromJSON = SharedContainerFromJSON;
function SharedContainerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'order': json['order'],
        'templateId': !(0, runtime_1.exists)(json, 'templateId') ? undefined : json['templateId'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'completed': !(0, runtime_1.exists)(json, 'completed') ? undefined : json['completed'],
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'fields': (json['fields'].map(ContainerField_1.ContainerFieldFromJSON)),
        'issuer': !(0, runtime_1.exists)(json, 'issuer') ? undefined : (0, EntityInfo_1.EntityInfoFromJSON)(json['issuer']),
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}
exports.SharedContainerFromJSONTyped = SharedContainerFromJSONTyped;
function SharedContainerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'order': value.order,
        'templateId': value.templateId,
        'name': value.name,
        'description': value.description,
        'completed': value.completed,
        'active': value.active,
        'fields': (value.fields.map(ContainerField_1.ContainerFieldToJSON)),
        'issuer': (0, EntityInfo_1.EntityInfoToJSON)(value.issuer),
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}
exports.SharedContainerToJSON = SharedContainerToJSON;

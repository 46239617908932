"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Share-Merits
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorsToJSON = exports.ErrorsFromJSONTyped = exports.ErrorsFromJSON = exports.instanceOfErrors = void 0;
/**
 * Check if a given object implements the Errors interface.
 */
function instanceOfErrors(value) {
    var isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "errors" in value;
    return isInstance;
}
exports.instanceOfErrors = instanceOfErrors;
function ErrorsFromJSON(json) {
    return ErrorsFromJSONTyped(json, false);
}
exports.ErrorsFromJSON = ErrorsFromJSON;
function ErrorsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'code': json['code'],
        'errors': json['errors'],
    };
}
exports.ErrorsFromJSONTyped = ErrorsFromJSONTyped;
function ErrorsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'code': value.code,
        'errors': value.errors,
    };
}
exports.ErrorsToJSON = ErrorsToJSON;

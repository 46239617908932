"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Share-Merits
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationResponseToJSON = exports.PaginationResponseFromJSONTyped = exports.PaginationResponseFromJSON = exports.instanceOfPaginationResponse = void 0;
var runtime_1 = require("../runtime");
var PaginationResponseCursor_1 = require("./PaginationResponseCursor");
/**
 * Check if a given object implements the PaginationResponse interface.
 */
function instanceOfPaginationResponse(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPaginationResponse = instanceOfPaginationResponse;
function PaginationResponseFromJSON(json) {
    return PaginationResponseFromJSONTyped(json, false);
}
exports.PaginationResponseFromJSON = PaginationResponseFromJSON;
function PaginationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hasMore': !(0, runtime_1.exists)(json, 'hasMore') ? undefined : json['hasMore'],
        'cursor': !(0, runtime_1.exists)(json, 'cursor') ? undefined : (0, PaginationResponseCursor_1.PaginationResponseCursorFromJSON)(json['cursor']),
    };
}
exports.PaginationResponseFromJSONTyped = PaginationResponseFromJSONTyped;
function PaginationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hasMore': value.hasMore,
        'cursor': (0, PaginationResponseCursor_1.PaginationResponseCursorToJSON)(value.cursor),
    };
}
exports.PaginationResponseToJSON = PaginationResponseToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Share-Merits
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharePagesResponseToJSON = exports.SharePagesResponseFromJSONTyped = exports.SharePagesResponseFromJSON = exports.instanceOfSharePagesResponse = void 0;
var runtime_1 = require("../runtime");
var Share_1 = require("./Share");
/**
 * Check if a given object implements the SharePagesResponse interface.
 */
function instanceOfSharePagesResponse(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfSharePagesResponse = instanceOfSharePagesResponse;
function SharePagesResponseFromJSON(json) {
    return SharePagesResponseFromJSONTyped(json, false);
}
exports.SharePagesResponseFromJSON = SharePagesResponseFromJSON;
function SharePagesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'shares': !(0, runtime_1.exists)(json, 'shares') ? undefined : (json['shares'].map(Share_1.ShareFromJSON)),
        'hasMore': !(0, runtime_1.exists)(json, 'hasMore') ? undefined : json['hasMore'],
        'offset': !(0, runtime_1.exists)(json, 'offset') ? undefined : json['offset'],
    };
}
exports.SharePagesResponseFromJSONTyped = SharePagesResponseFromJSONTyped;
function SharePagesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'shares': value.shares === undefined ? undefined : (value.shares.map(Share_1.ShareToJSON)),
        'hasMore': value.hasMore,
        'offset': value.offset,
    };
}
exports.SharePagesResponseToJSON = SharePagesResponseToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Share-Merits
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LimitOffsetPaginationResponseToJSON = exports.LimitOffsetPaginationResponseFromJSONTyped = exports.LimitOffsetPaginationResponseFromJSON = exports.instanceOfLimitOffsetPaginationResponse = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the LimitOffsetPaginationResponse interface.
 */
function instanceOfLimitOffsetPaginationResponse(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfLimitOffsetPaginationResponse = instanceOfLimitOffsetPaginationResponse;
function LimitOffsetPaginationResponseFromJSON(json) {
    return LimitOffsetPaginationResponseFromJSONTyped(json, false);
}
exports.LimitOffsetPaginationResponseFromJSON = LimitOffsetPaginationResponseFromJSON;
function LimitOffsetPaginationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hasMore': !(0, runtime_1.exists)(json, 'hasMore') ? undefined : json['hasMore'],
        'offset': !(0, runtime_1.exists)(json, 'offset') ? undefined : json['offset'],
    };
}
exports.LimitOffsetPaginationResponseFromJSONTyped = LimitOffsetPaginationResponseFromJSONTyped;
function LimitOffsetPaginationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hasMore': value.hasMore,
        'offset': value.offset,
    };
}
exports.LimitOffsetPaginationResponseToJSON = LimitOffsetPaginationResponseToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Share-Merits
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareToJSON = exports.ShareFromJSONTyped = exports.ShareFromJSON = exports.instanceOfShare = void 0;
var runtime_1 = require("../runtime");
var SharedContainer_1 = require("./SharedContainer");
/**
 * Check if a given object implements the Share interface.
 */
function instanceOfShare(value) {
    var isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "shareID" in value;
    isInstance = isInstance && "containerCount" in value;
    return isInstance;
}
exports.instanceOfShare = instanceOfShare;
function ShareFromJSON(json) {
    return ShareFromJSONTyped(json, false);
}
exports.ShareFromJSON = ShareFromJSON;
function ShareFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdAt': json['createdAt'],
        'expiration': !(0, runtime_1.exists)(json, 'expiration') ? undefined : json['expiration'],
        'shareID': json['shareID'],
        'containerCount': json['containerCount'],
        'containers': !(0, runtime_1.exists)(json, 'containers') ? undefined : (json['containers'].map(SharedContainer_1.SharedContainerFromJSON)),
    };
}
exports.ShareFromJSONTyped = ShareFromJSONTyped;
function ShareToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdAt': value.createdAt,
        'expiration': value.expiration,
        'shareID': value.shareID,
        'containerCount': value.containerCount,
        'containers': value.containers === undefined ? undefined : (value.containers.map(SharedContainer_1.SharedContainerToJSON)),
    };
}
exports.ShareToJSON = ShareToJSON;

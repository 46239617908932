"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Share-Merits
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateSharePropertiesToJSON = exports.CreateSharePropertiesFromJSONTyped = exports.CreateSharePropertiesFromJSON = exports.instanceOfCreateShareProperties = void 0;
var runtime_1 = require("../runtime");
var Container_1 = require("./Container");
/**
 * Check if a given object implements the CreateShareProperties interface.
 */
function instanceOfCreateShareProperties(value) {
    var isInstance = true;
    isInstance = isInstance && "containers" in value;
    return isInstance;
}
exports.instanceOfCreateShareProperties = instanceOfCreateShareProperties;
function CreateSharePropertiesFromJSON(json) {
    return CreateSharePropertiesFromJSONTyped(json, false);
}
exports.CreateSharePropertiesFromJSON = CreateSharePropertiesFromJSON;
function CreateSharePropertiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'expiration': !(0, runtime_1.exists)(json, 'expiration') ? undefined : json['expiration'],
        'containers': (json['containers'].map(Container_1.ContainerFromJSON)),
    };
}
exports.CreateSharePropertiesFromJSONTyped = CreateSharePropertiesFromJSONTyped;
function CreateSharePropertiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'expiration': value.expiration,
        'containers': (value.containers.map(Container_1.ContainerToJSON)),
    };
}
exports.CreateSharePropertiesToJSON = CreateSharePropertiesToJSON;

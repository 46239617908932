"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Share-Merits
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareResponseToJSON = exports.ShareResponseFromJSONTyped = exports.ShareResponseFromJSON = exports.instanceOfShareResponse = void 0;
var runtime_1 = require("../runtime");
var Share_1 = require("./Share");
/**
 * Check if a given object implements the ShareResponse interface.
 */
function instanceOfShareResponse(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShareResponse = instanceOfShareResponse;
function ShareResponseFromJSON(json) {
    return ShareResponseFromJSONTyped(json, false);
}
exports.ShareResponseFromJSON = ShareResponseFromJSON;
function ShareResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'share': !(0, runtime_1.exists)(json, 'share') ? undefined : (0, Share_1.ShareFromJSON)(json['share']),
    };
}
exports.ShareResponseFromJSONTyped = ShareResponseFromJSONTyped;
function ShareResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'share': (0, Share_1.ShareToJSON)(value.share),
    };
}
exports.ShareResponseToJSON = ShareResponseToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Share-Merits
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldTypeToJSON = exports.FieldTypeFromJSONTyped = exports.FieldTypeFromJSON = exports.FieldType = void 0;
/**
 * Abstract representation of a Column's DataType - use one of its implementing children like Bool or Date.
 * @export
 */
exports.FieldType = {
    Bool: 'Bool',
    Date: 'Date',
    DateTime: 'DateTime',
    Email: 'Email',
    Markdown: 'Markdown',
    Number: 'Number',
    PhoneNumber: 'PhoneNumber',
    Text: 'Text',
    Blob: 'Blob',
    Json: 'JSON'
};
function FieldTypeFromJSON(json) {
    return FieldTypeFromJSONTyped(json, false);
}
exports.FieldTypeFromJSON = FieldTypeFromJSON;
function FieldTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.FieldTypeFromJSONTyped = FieldTypeFromJSONTyped;
function FieldTypeToJSON(value) {
    return value;
}
exports.FieldTypeToJSON = FieldTypeToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Share-Merits
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContainerFieldToJSON = exports.ContainerFieldFromJSONTyped = exports.ContainerFieldFromJSON = exports.instanceOfContainerField = void 0;
var runtime_1 = require("../runtime");
var FieldKindInfo_1 = require("./FieldKindInfo");
/**
 * Check if a given object implements the ContainerField interface.
 */
function instanceOfContainerField(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "templateFieldID" in value;
    isInstance = isInstance && "fieldKind" in value;
    return isInstance;
}
exports.instanceOfContainerField = instanceOfContainerField;
function ContainerFieldFromJSON(json) {
    return ContainerFieldFromJSONTyped(json, false);
}
exports.ContainerFieldFromJSON = ContainerFieldFromJSON;
function ContainerFieldFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'description': json['description'],
        'value': json['value'],
        'templateFieldID': json['templateFieldID'],
        'fieldKind': (0, FieldKindInfo_1.FieldKindInfoFromJSON)(json['fieldKind']),
        'metadata': !(0, runtime_1.exists)(json, 'metadata') ? undefined : json['metadata'],
    };
}
exports.ContainerFieldFromJSONTyped = ContainerFieldFromJSONTyped;
function ContainerFieldToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'value': value.value,
        'templateFieldID': value.templateFieldID,
        'fieldKind': (0, FieldKindInfo_1.FieldKindInfoToJSON)(value.fieldKind),
        'metadata': value.metadata,
    };
}
exports.ContainerFieldToJSON = ContainerFieldToJSON;
